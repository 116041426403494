export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, shrink-to-fit=no"},{"hid":"description","name":"description","content":"Take care for public spaces"}],"link":[{"rel":"icon","type":"image/png","href":"/icons/favicon-16x16.png"},{"rel":"icon","type":"image/png","href":"/icons/favicon-32x32.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/icons/apple-touch-icon.png"},{"rel":"manifest","href":"/icons/manifest.json"},{"rel":"mask-icon","href":"/icons/safari-pinned-tab.svg","color":"#5bbad5"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"de"},"title":"lessmess.io"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'