<template>
  <NuxtLoadingIndicator color="#FDC861" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { useGraphqlQuery } from '#graphql-composable'
import { useDefaultStore } from '~/stores/defaultStore'

const defaultStore = useDefaultStore()

const config = useRuntimeConfig()
// Read the host from .env NUXT_HOST to support multi site setup.
const host = config.host
await callOnce(async () => {
  const data = await useGraphqlQuery({
    name: 'initData',
    variables: { },
    fetchOptions: {
      headers: {
        host,
        'x-forwarded-host': host,
      },
    },
  }).then((v) => {
    const mainMenu
        = v.data.mainMenu && v.data.mainMenu.__typename === 'Menu'
          ? v.data.mainMenu
          : undefined

    return {
      mainMenu,
      footerMenu: v.data.footerMenu,
      footerSubmenu: v.data.footerSubmenu,
    }
  })
  defaultStore.setMenus(data)
})

useHead({
  htmlAttrs: {
    lang: 'de',
  },
})
</script>
