<template>
  <div class="container">
    <slot />
  </div>
</template>

<script setup lang="ts">
</script>

<style lang="scss">
.outline {
  box-shadow: -1px 0px 0px 0px $grey-light, 1px 0px 0px 0px $grey-light;
}
</style>
