<template>
  <section class="section">
    <slot />
  </section>
</template>

<script setup lang="ts">

</script>

<style lang="scss">
.section {
  &.has-border-section {
    border-top: 1px solid $grey-light;
    border-bottom: 1px solid $grey-light;

    &:last-of-type {
      border-bottom: none;
    }
  }

  &.has-border-bottom {
    border-bottom: 2px solid $grey-lighter;

    padding: toRem(30) toRem(30);

    @include tablet {
      padding: toRem(20) toRem(30);
    }

    @include widescreen {
      padding: toRem(60) toRem(30) toRem(60) toRem(30);
    }
  }

  &.no-top {
    padding-top: 0 !important;
  }
}

.section.banner + .section.banner {
  padding-top: 0;
}

//.section {
//  @include tablet {
//    padding: toRem(20) toRem(30);
//  }
//
//  @include widescreen {
//    padding: toRem(40) toRem(30);
//  }
//}

.section.is-narrow {
  @include tablet {
    padding: toRem(20) toRem(30);
  }

  @include widescreen {
    padding: toRem(40) toRem(30);
  }
}

.section.is-content {
  padding: toRem(20) toRem(30);

  @include tablet {
    padding: toRem(40) toRem(30);
  }

  @include widescreen {
    padding: toRem(60) toRem(30);
  }
}

.section.is-title {
  padding: toRem(40) toRem(30) toRem(0);

  @include tablet {
    padding: toRem(60) toRem(30) toRem(0);
  }

  @include widescreen {
    padding: toRem(120) toRem(30) toRem(0);
  }
}

.section.is-medium {
  padding: toRem(40) toRem(30);

  @include tablet {
    padding: toRem(40) toRem(30);
  }

  @include widescreen {
    padding: toRem(80) toRem(30);
  }
}

.section.is-large {
  padding: toRem(40) toRem(30);

  @include tablet {
    padding: toRem(60) toRem(30);
  }

  @include widescreen {
    padding: toRem(120) toRem(30);
  }
}

.section.is-title-only {
  padding: toRem(40) toRem(30);

  @include tablet {
    padding: toRem(60) toRem(30) toRem(40) toRem(30);
  }

  @include widescreen {
    padding: toRem(120) toRem(30) toRem(80) toRem(30);
  }
}

.section.is-home {
  padding-top: toRem(80);
  padding-bottom: toRem(80);
  @include desktop {
    padding-top: toRem(120);
  }
}
</style>
