import { defineNuxtPlugin } from 'nuxt/app'

/**
 * This is only called when performing a query or mutation from within the nuxt
 * app (e.g. not via custom server routes).
 */
export default defineNuxtPlugin({
  name: 'graphql-fetch-options',
  setup(app) {
    const state = useGraphqlState(app)

    state.fetchOptions = {
      onResponse(result: any) {

      },
      async onRequest({ options }: any) {
        try {
          if (!options.headers) {
            options.headers = {}
          }
          options.headers = useRequestHeaders()

          return options
        }
        catch (e) {
          console.error('Exception in onRequest handler:', e)
        }
      },
    }
  },
})
