<template>
  <NuxtLayout>
    <DefaultSection class="is-medium">
      <Container class="is-errorpage">
        <h1 class="title is-size-2">
          {{ title }}
        </h1>

        <h2 class="title is-size-4 ">
          {{ titleLight }}
        </h2>
        <p class="is-size-6 mb-4">
          {{ text }}
        </p>

        <nuxt-link class="button is-sky-blue has-text-weight-bold" to="/">
          Zur Startseite
        </nuxt-link>
      </Container>
    </DefaultSection>
  </NuxtLayout>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'

const props = defineProps({
  error: Object as PropType<ErrorObject>,
})

useHead({
  bodyAttrs: {
    class: 'has-navbar-fixed-top',
  },
})

interface ErrorMessages {
  accessDeniedTitle: string
  accessDeniedText: string
  pageNotFoundTitle: string
  pageNotFoundText: string
  serverErrorTitle: string
  goToHome: string
}

const ERRORS: Record<string, ErrorMessages> = {
  de: {
    accessDeniedTitle: 'Zugriff verweigert',
    accessDeniedText: 'Sie haben keinen Zugriff auf diese Seite.',
    pageNotFoundTitle: 'Seite nicht gefunden',
    pageNotFoundText:
      'Die von Ihnen angeforderte Seite konnte nicht gefunden werden. Benutzen Sie unsere Suchfunktion um den gewünschten Inhalt zu finden.',
    serverErrorTitle: 'Seite nicht verfügbar',
    goToHome: 'Zur Startseite',
  },
}

interface ErrorObject {
  url?: string
  statusCode?: number
  statusMessage?: string
  message?: string
  stack?: string
}

const language = computed<string>(() => {
  return 'de'
})

const texts = computed<ErrorMessages>(() => {
  if (language.value && ERRORS[language.value]) {
    return ERRORS[language.value]
  }
  return ERRORS.de
})

const title = computed(() => {
  if (props.error?.statusCode === 403) {
    return texts.value.accessDeniedTitle
  }
  else if (props.error?.statusCode === 404) {
    return texts.value.pageNotFoundTitle
  }

  return texts.value.serverErrorTitle
})

const titleLight = computed(() => {
  if (props.error?.statusCode) {
    return `Error ${props.error.statusCode}`
  }
  return 'Error'
})

const text = computed(() => {
  if (props.error?.statusCode === 403) {
    return texts.value.accessDeniedText
  }
  else if (props.error?.statusCode === 404) {
    return texts.value.pageNotFoundText
  }

  return props.error?.message
})
</script>

<style lang="scss">
.is-errorpage {
  min-height: 300px;
}
</style>
