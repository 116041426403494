import { defineStore } from 'pinia'

export const useDefaultStore = defineStore({
  id: 'mainStore',
  state: () => ({
    fetched: false,
    mainMenu: {},
    footerMenu: {},
    footerSubmenu: {},
    isMobile: true,
  }),
  actions: {
    setMenus(data) {
      this.mainMenu = data.mainMenu
      this.footerMenu = data.footerMenu
      this.footerSubmenu = data.footerSubmenu
    },
    setIsMobile(isMobile) {
      this.isMobile = isMobile
    },
  },
})
