import type { OrugaOptions } from '@oruga-ui/oruga-next'
import { ConfigProgrammatic, Modal } from '@oruga-ui/oruga-next'
import { bulmaConfig } from '@oruga-ui/theme-bulma'

export default defineNuxtPlugin((nuxtApp) => {
  const config: OrugaOptions = {
    ...bulmaConfig,
  }

  // Fix problem with carousel
  // https:// github.com/oruga-ui/oruga/issues/683
  config.carousel.wrapperClass = 'carousel-scene'

  ConfigProgrammatic.setOptions(config)
  nuxtApp.vueApp.use(Modal)
  // nuxtApp.vueApp.use(Carousel)
})
