export const options = {
  "images": true,
  "videos": false,
  "audios": false,
  "iframes": false,
  "native": false,
  "directiveOnly": true,
  "defaultImage": false,
  "loadingClass": "is-loading",
  "loadedClass": "is-loaded",
  "appendClass": "has-lazyloading",
  "observerConfig": {}
}