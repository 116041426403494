import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AsyncPageDependency',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },

  setup() {
    const slots = useSlots()
    if (!slots.default) {
      return
    }

    const nuxtApp = useNuxtApp()

    if (process.server) {
      return new Promise((resolve) => {
        const resolver = () => {
          resolve(() => slots.default!())
        }
        if (nuxtApp._pageRendered) {
          resolver()
        } else {
          nuxtApp.hooks.hookOnce('page:rendered', resolver)
          nuxtApp.hooks.hookOnce('app:error', resolver)
          nuxtApp.hooks.hookOnce('vue:error', resolver)
        }
      })
    }

    return () => slots.default!()
  },
})
